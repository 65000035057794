body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-container--bottom-center {
  left: 300px !important;
  bottom: -20px !important;
  width: calc(100% - 655px) !important;
  transform: translateX(0) !important;
  padding: 0 !important;
}

.Toastify__toast-container--bottom-center .Toastify__toast--success {
  background: #042b59 !important;
  border-radius: unset;
}

.Toastify__toast-container--bottom-center .Toastify__toast--success div {
  font-family: Poppins,sans-serif;
}
.Toastify__toast-container--bottom-center .Toastify__zoom-enter {
  display: none;
}

.Toastify__toast-container--bottom-center .Toastify__close-button--colored {
  display: flex;
  align-self: center;
}

.Toastify__toast-container--bottom-center .Toastify__close-button--colored svg {
  height: 21px;
  width: 21px;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}