@import "../../../../assets/scss/variables.scss";

.profile-notes {
  scroll-margin-top: 180px;
  .back-box {
    display: none;
    margin: 12px 0 30px;
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border: 1px solid $black;
      border-radius: 50%;
      i {
        transform: rotate(180deg);
        &::before {
          color: $black;
        }
      }
    }
    @media (max-width: 1200px) {
      display: block;
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 25px;
    h3 {
      font-size: 30px;
      line-height: 45px;
      color: $maastright-blue;
      margin-bottom: 0;
      @media (max-width: 1200px) {
        font-size: 20px;
        line-height: 27px;
      }
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      row-gap: 15px;
      text-align: center;
      margin: 0 -15px;
      padding-bottom: 25px;
      border-bottom: 1px solid $desired-down;
    }
  }
  &-wrapp {
    .notes-header {
      padding: 25px;
      border-bottom: 1px solid $white-edgard;
      @media (max-width: 1200px) {
        display: none;
      }
    }
    .notes-body {
      padding: 20px 0;
      @media (max-width: 1200px) {
        margin: 0 -15px;
      }
    }
  }
  @media (max-width: 1200px) {
    margin-top: -40px;
  }
}
.filter-btn {
  display: flex;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 13px;
  line-height: 13px;
  color: $maastright-blue;
  padding: 0;
  &:focus {
    outline: 0;
  }
  .down {
    margin-top: -1px;
  }
  .up {
    transform: rotate(180deg);
    margin-top: 2px;
  }
  i {
    font-size: 7px;
  }
}
