@import "../../assets/scss/variables.scss";

.footer {
  background-color: $effervescent-blue;
  padding: 40px;
  color: white;
  &-rights {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 991px) {
      row-gap: 50px;
      flex-direction: column-reverse;
    }
  }

  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0;
    @media (max-width: 991px) {
      text-align: center;
      max-width: 70%;
      margin: 0 auto;
    }
  }

  a {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px !important;
    line-height: 18px;
    color: inherit;
    span {
      width: 30px;
      height: 30px;
      border-radius: 50px;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      font-size: 14px;
    }
    @media (max-width: 991px) {
      font-weight: 300;
    }
  }
}
