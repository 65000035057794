@import "../../../../assets/scss/variables.scss";

.button {
  margin: 0 auto;
  margin-top: 40px;
  max-width: 275px;
}
.refer-friend {
  padding-top: 25px;
  .back-box {
    display: none;
    margin: 12px 0 30px;
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border: 1px solid $black;
      border-radius: 50%;
      i {
        transform: rotate(180deg);
        &::before {
          color: $black;
        }
      }
    }
    @media (max-width: 1200px) {
      display: block;
    }
  }
  .refer {
    display: flex;
    flex-flow: column;
    padding-left: 5rem;
    padding-right: 5rem;
    b {
      font-weight: bold;
    }
  }
  h3 {
    font-size: 30px;
    line-height: 45px;
    text-align: center;
    color: $maastright-blue;
    margin-bottom: 15px;
    & + p {
      text-align: center;
      font-weight: 300;
      font-size: 18px;
      line-height: 34px;
      color: $black;
      margin-bottom: 0;
    }
  }
  p {
    // text-align: center;
    font-weight: 300;
    font-size: 18px;
    line-height: 34px;
    color: $black;
    margin-bottom: 0;
  }
  &-form {
    margin-top: 40px;
    .custom-row {
      display: flex;
      column-gap: 25px;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      .inputs-box {
        display: flex;
        gap: 20px;
        flex-grow: 1;
        & > div {
          flex-grow: 1;
        }
        @media (max-width: 1200px) {
          flex-direction: column;
        }
      }
      .row-control {
        display: flex;
        padding-bottom: 7px;
        align-items: center;
        column-gap: 15px;
        .control-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border: 1px solid $black;
          border-radius: 50%;
          align-self: flex-end;
          cursor: pointer;
          i {
            font-size: 16px;
          }
          @media (max-width: 1200px) {
            align-self: flex-start;
          }
        }
        .control-text {
          display: none;
          font-weight: 500;
          font-size: 15px;
          line-height: 15px;
          color: $maastright-blue;
          @media (max-width: 1200px) {
            display: block;
          }
        }
      }
      @media (max-width: 1200px) {
        row-gap: 25px;
        flex-direction: column;
      }
    }
    button {
      max-width: 270px;
      margin: 45px auto 0;
      @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
        margin-top: 40px;
      }
    }
  }
  .social-box {
    text-align: center;
    margin-top: 40px;
    p {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: $effervescent-blue;
      margin-bottom: 20px;
    }
    ul {
      justify-content: center;
      column-gap: 20px;
      font-size: 20px;
      li {
        display: inline-flex;
        a {
          display: inline-flex;
        }
      }
    }
  }

  input::placeholder {
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    color: $gray-light;
  }
  @media (max-width: 1200px) {
    padding-top: 0;
    margin-top: -40px;
    h3 {
      font-size: 20px;
      line-height: 27px;
      & + p {
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
}

.error-message {
  color: $red;
  font-size: 14px;
}