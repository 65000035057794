

.video {
    width: 487px;
    height: 274px;
    position: relative;

    .wrapper {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 15px;
        position: relative;
        iframe {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &:after {
        content: "";
    position: absolute;
    display: block;
    width: calc(100% - 20px);
    height: 45px;
    background-color: #fcb912;
    border-radius: 25px;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    z-index: -1;
    }
}