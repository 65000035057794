@import "../../assets/scss/variables.scss";
// @import "";

.dashboard-page {
  display: flex;
  column-gap: 3.4vw;

  .dashboard-page-main {
    padding-top: 40px;
    width: unquote("calc(100% - 655px - 6.8vw)");
  }
  h2 {
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 20px;
    color: $maastright-blue;
  }
}
.search-bars {
  box-shadow: none;
  border: none;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 10px;
}