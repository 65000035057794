@import "../../../../../../assets/scss/variables.scss";

.auth-box {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  // margin-top: 30vh;

  &-icon {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      width: 75px;
      height: 75px;
      border-radius: 10px;
      background-color: $bisquit;
    }
  }
  &-text {
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 25px;
    text-align: center;
    color: $black;
    span {
      font-weight: 400;
    }
  }
  .login {
    margin-top: 20px;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    color: $black;
    text-align: center;
    a {
      font-weight: 400;
      color: inherit;
    }
  }
}
