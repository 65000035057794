@import "../../assets/scss/variables.scss";

.saved-podcasts-title {
  p {
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    &:first-child {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      line-height: 30px;
      font-weight: 700;
      max-width: 286px;
      width: max-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
      display: block;
      @media (min-width: 1280px) {
        max-width: none;
        width: auto;
        white-space: normal;
        max-height: 90px;
      }
    }
    & a {
      color: inherit;
    }
  }
}
.separator {
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background-color: $bank-vault;
  margin: 0 10px;
}
