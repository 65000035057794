@import "../../assets/scss/variables.scss";

.play-icon {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 27px;
  min-width: 27px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg circle,
  svg path {
    transition: fill 0.2s ease-in-out;
  }
  .play-icon-circle {
    fill: transparent;
  }
  .play {
    fill: transparent;
  }
  &:hover {
    .play-icon-circle {
      fill: $orange-yellow;
    }
    .play {
      fill: $white;
    }
  }
}
