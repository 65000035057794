@import "../../../../assets/scss/variables.scss";

.container {
  scroll-margin-top: 180px;
}
.sort {
  column-gap: 10px;
  max-width: 1050px;
  margin: 0 auto 20px;
  font-weight: 300;
  font-size: 13px;
  line-height: 13px;
  button {
    font-size: 18px;
    font-weight: 400;
  }
}

.featured-items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 40px;
  padding-bottom: 40px;
  margin: 0 auto;
  margin-top: 15px;
}

.no-result {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: $maastright-blue;
  text-align: center;
  margin-top: 50px;
}