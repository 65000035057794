@import "../../../../../../assets/scss/variables.scss";

.speakers-item {
  max-width: 1050px;
  margin: 0 auto 35px;
  .speaker-info {
    column-gap: 20px;
    &-avatar {
      position: relative;
      width: 80px;
      height: 80px;
      flex-shrink: 0;
      border-radius: 50%;
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        left: 3px;
        top: 3px;
        width: inherit;
        height: inherit;
        border: 1px solid $black;
        border-radius: 50%;
        z-index: -1;
      }

      img {
        object-fit: cover;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        background-color: #ecedf1;
      }
    }
    p {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      line-height: 27px;
      font-weight: 700;
      color: $maastright-blue;
      margin-bottom: 0;
    }
  }
  .speaker-actions {
    column-gap: 25px;
    button:first-child {
      font-size: 25px;
    }
  }
  .speakers-descr {
    margin: 20px 0 25px;
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    color: $black;
  }
  .speakers-item-footer {
    padding-bottom: 35px;
    color: $black;
    border-bottom: 1px solid $white-edgard;
    p {
      font-family: "Mulish", sans-serif;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 0;
    }
    a {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: inherit;
    }
  }
  &:last-child .speakers-item-footer {
    border-bottom: 0;
  }
}

.no-result {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: $maastright-blue;
  text-align: center;
  margin-top: 50px;
}