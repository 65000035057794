@import "../../../../../../assets/scss/variables.scss";

.slide {
  margin-bottom: 5px;
  border: 0;
  & > div {
    padding: 55px 35px 50px 45px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    &::before {
      background: $greyGradient;
      content: "";
      position: absolute;
      display: block;
      inset: 0;
      // background-color: $maastright-blue;
      opacity: 0.25;
    }
    & > div {
      position: relative;
      z-index: 1;
    }
  }
  .slide-info {
    color: $white;
    .top-topic {
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 15px;
    }
    .track-name {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 26px;
      line-height: 39px;
      margin-bottom: 25px;
      max-width: 400px;
      min-height: 120px;
    }
    .author {
      column-gap: 10px;
      &-avatar {
        position: relative;
        width: 43px;
        height: 43px;
        flex-shrink: 0;
        z-index: 1;
        img {
          width: inherit;
          height: inherit;
          border-radius: 50%;
          object-position: center;
          object-fit: cover;
          background-color: #ecedf1;
        }
        &::after {
          content: "";
          position: absolute;
          top: 2px;
          left: 2px;
          width: inherit;
          height: inherit;
          background-color: $orange-yellow;
          border-radius: 50%;
          z-index: -1;
        }
      }
      p {
        font-size: 15px;
        line-height: 26px;
        font-weight: 300;
        margin-bottom: 0;
      }
    }
  }
}
