@import "../../../../assets/scss/variables.scss";

.left-sidebar {
  width: 300px;
  padding: 81px 25px 20px;
  margin-top: -42px;
  border-right: 1px solid $white-edgard;

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin: 0;
  }

  .dashboard-slide-menu {
    padding: 35px 25px 0;
    display: flex;
    column-gap: 15px;
    margin-bottom: 40px;
    span {
      width: 35px;
      height: 35px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $maastright-blue;
      border-radius: 50%;
      font-size: 12px;
      cursor: pointer;
      i::before {
        color: $maastright-blue;
      }
      &.disabled {
        cursor: default;
        user-select: none;
        opacity: 0.4;
      }
      &.slide-left {
        transform: rotate(180deg);
      }
    }
  }

  .sidebar {
    padding: 0 25px;
    h6 {
      font-size: 16px;
      font-weight: 600;
      font-family: 'Poppins';
      color: #021C3C;
      margin-bottom: 30px;
    }
    .rating {
      margin-top: 7px;
      margin-bottom: 25px;
      margin-left: -10px;
    }
    &-list {
      margin-bottom: 60px;
      li {
        display: block;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &.active::after {
          content: "";
          position: absolute;
          display: inline-block;
          height: 100%;
          width: 3px;
          top: 0;
          right: -51px;
          background-color: $orange-yellow;
        }
        .link-box {
          display: flex;
          column-gap: 15px;
          align-items: center;
          .left-icon-box {
            width: 40px;
            height: 40px;
            font-size: 22px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background-color: $bisquit;
            border-radius: 50%;
            i::before {
              color: $effervescent-blue;
            }
          }
          .sidebar-link {
            font-size: 16px;
            line-height: 16px;
            color: $black;
          }
        }
      }
    }
  }

  .sidebar-submenu {
    padding: 0 25px;
    &-title {
      display: flex;
      align-items: center;
      column-gap: 15px;
      margin-bottom: 25px;
      i {
        font-size: 20px;
      }
    }
    &-list {
      li {
        display: block;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 25px;
        }
        &.active::after {
          content: "";
          position: absolute;
          display: inline-block;
          height: 100%;
          width: 3px;
          top: 0;
          right: -51px;
          background-color: $orange-yellow;
        }
        a {
          font-size: 16px;
          line-height: 16px;
          font-weight: 300;
          color: $black;
        }
      }
    }
  }
}
.dropdown {
  background-color: #FFF8E8;
  padding: 5px 12px;
  border-radius: 5px;
  margin-bottom: 60px;
  border-bottom: 1px solid #EDEDED;
  cursor: pointer;
  z-index: 7;
  section {
    font-style: italic;
    font-family: 'Sofia Sans';
    font-weight: 300;
    font-size: 13px;
    line-height: 25px;
    span {
      // font-family: 'Sofia Pro Light Az';
      text-transform: capitalize;
      margin-right: 8px;
      
    }
    i {
      padding-top: 2px;
    }
  }
}