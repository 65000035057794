@import "../../../../../assets/scss/variables.scss";

.notes-item {
  padding: 20px 25px;
  &:not(:last-child) {
    border-bottom: 1px solid $desired-down;
  }
  &:first-child {
    padding-top: 0;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    .date {
      font-weight: 300;
      font-size: 13px;
      line-height: 24px;
      margin-bottom: 3px;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      color: $maastright-blue;
      margin-bottom: 0;
    }
    span {
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $black;
      border-radius: 50%;
      cursor: pointer;
      i {
        font-size: 12px;
        transform: rotate(90deg);
        transition: transform 0.2s ease-in-out;
        &.up {
          transform: rotate(270deg);
        }
        &::before {
          color: $black;
        }
      }
    }
  }
  &-body {
    @media (max-width: 1200px) {
      padding: 25px 0 0;
    }
    .view {
      margin-top: 20px;
      display: flex;
    }
  }
}
