@import "../../assets/scss/variables.scss";

.user-level {
  background-color: $beige;
  border-radius: 5px;
  padding: 7px 10px;
  column-gap: 10px;
  i {
    font-size: 22px;
    &::before {
      color: $red;
    }
  }
  span {
    display: inline-block;
    font-weight: 300;
    font-size: 13px;
    line-height: 13px;
    color: $maastright-blue;
    &.bold-text {
      font-weight: 700;
      margin-right: 5px;
    }
  }
}
