@import "../../../../../assets/scss/variables.scss";

.request-close {
  margin-bottom: 35px;
}
.request-body {
  max-width: 580px;
  margin: 0 auto 100px;
  h2 {
    margin-bottom: 15px;
  }
  & > p {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .form {
    margin-top: 50px;

    & > a {
      font-weight: 500;
      line-height: 15px;
      font-size: 15px;
      color: $black;
    }

    label {
      margin-bottom: 0;
    }
    .request-payout {
      margin-bottom: 40px;
      label {
        font-size: 15px;
        margin-bottom: 15px;
      }
    }
    .podcast-rating {
      margin-bottom: 40px;
      & > p {
        font-weight: 300;
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 20px;
        color: $black;
      }
    }
    .podcast-review-date {
      margin-bottom: 40px;
      & > p {
        font-weight: 300;
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 20px;
        color: $black;
      }
      .datepicker-error {
        color: #f51d00;
        font-size: 14px;
        bottom: -22px;
        display: block;
        font-weight: 400;
      }
      .calendar {
        border: 1px solid #000;
        border-radius: 10px;
        padding: 5px;
        font-weight: 300;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
        &--error {
          border: 1px solid #f51d00;
        }
      }
    }
    .comments {
      margin: 35px 0 40px;
    }
    .review-admin {
      margin-top: 20px;
      & > div {
      }
    }
    .submit-button {
      button {
        max-width: 250px;
        margin: 35px auto 0;
      }
    }
  }
}

.external-podcast {
  div {
    div {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
}

.request-reason {
  div {
    align-items: flex-start;
  }
}

#requestedMoreThanAllowed {
  background: #042b59;
}