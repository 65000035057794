@import "../../../../../../assets/scss/variables.scss";

.list-container {
  .header {
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: $maastright-blue;
      margin: 0;
    }
    a {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: $effervescent-blue;
    }
  }
  .no-items {
    margin: 15px 0 55px;
    font-style: italic;
  }
  &-list {
    margin-top: 30px;
    margin-bottom: 55px;
    li {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      a {
        display: flex;
        column-gap: 20px;
        color: $black;
        .item-image {
          position: relative;
          width: 43px;
          height: 43px;
          z-index: 1;
          img {
            border-radius: 50%;
            object-position: center;
            object-fit: cover;
            background-color: #ecedf1;
            width: inherit;
            height: inherit;
          }
          &::after {
            content: "";
            position: absolute;
            top: 2px;
            left: 2px;
            width: inherit;
            height: inherit;
            border: 1px solid $black;
            border-radius: 50%;
            z-index: -1;
          }
        }
        p {
          font-size: 15px;
          line-height: 26px;
          max-width: 163px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }
        span {
          font-weight: 300;
          font-size: 13px;
          line-height: 13px;
        }
      }
    }
  }
}
