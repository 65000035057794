.filters-wrapper {
    display: flex;
    flex-direction: column;
    gap: 50px;
    position: relative;
    .filter-item {
        section {
            font-weight: 300;
            &:hover {
                font-weight: 600;
            }
            i {
                transform: rotate(-90deg);
            }
        }
        & > [class^="MultiSelect_drop-down"] {
            position: unset;
            [class^="MultiSelect_drop-down-wrapp"] {
                position: absolute;
                top: -30px;
                left: 200px;
            }
        }
    }
    .control {
        display: flex;
        gap: 8px
    }
    .btn-clear {
        border-radius: 9999px;
        border: 1px solid #000;
        &:hover {
            border-radius: 9999px;
            border: 1px solid #000;
        }
    }
    .btn-apply {
        border-radius: 9999px;
        border-color: #042B59;
        background-color: #042B59;
        color: white;
        &:hover {
            background-color: #042B59;
            color: white;
        }
    }
}