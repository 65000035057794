@import "../../../../assets/scss/variables.scss";

.profile-details {
  margin-bottom: 50px;
  &-info {
    display: flex;
    align-items: center;
    column-gap: 55px;
    .img-box {
      flex-shrink: 0;
      position: relative;
      .img-radius {
        text-align: center;
        position: relative;
        display: flex;
        margin: 0 auto;
        max-width: 175px;
        height: 185px;
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          -webkit-clip-path: url(#profile);
          clip-path: url(#profile);
        }
        &::after {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='170' height='178' viewBox='0 0 170 178' fill='none'%3E%3Cpath d='M156.246 34.4441C154.777 23.2473 145.845 14.5049 134.62 13.2763L27.6651 1.57045C13.1655 -0.0164863 0.499527 11.3388 0.499527 25.925V152.516C0.499527 167.33 13.5455 178.755 28.2306 176.802L148.163 160.845C161.559 159.063 170.982 146.771 169.224 133.373L156.246 34.4441Z' stroke='%23052B5A'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 2px;
          left: -6px;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
        @media (max-width: 1200px) {
          display: none;
        }
      }
      .img-edit-mobile {
        position: relative;
        width: 93px;
        display: none;
        button {
          position: absolute;
          right: -6px;
          top: 0;
          width: 29px;
          height: 29px;
          border-radius: 50%;
          background-color: $red;
          display: none;
          @media (max-width: 1200px) {
            display: inline-flex;
          }
        }
        @media (max-width: 1200px) {
          display: block;
        }
      }
    }
    .user-details {
      display: flex;
      column-gap: 25px;
      flex-wrap: wrap;
      flex-grow: 1;
      justify-content: space-between;
      &-box {
        display: flex;
        row-gap: 16px;
        flex-direction: column;
        flex: 1;
      }
      &-list {
        display: flex;
        column-gap: 13px;
        flex-wrap: wrap;
        li {
          display: flex;
          align-items: center;
          column-gap: 11px;
          i {
            font-size: 22px;
            &.user-details-rocket::before {
              color: $red;
              @media (max-width: 1200px) {
                color: $black;
              }
            }
          }
          span {
            font-weight: 300;
            font-size: 15px;
            line-height: 25px;
          }
        }
      }
      &-name {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 52px;
        line-height: 62px;
        color: $maastright-blue;
        flex-grow: 1;
        margin-bottom: 0;
        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 27px;
          text-align: center;
        }
      }
      @media (max-width: 1200px) {
        flex-direction: column;
        margin-top: 20px;
        &-list {
          flex-direction: column;
          row-gap: 20px;
          align-items: center;
          i::before {
            color: $black;
          }
        }
      }
      @media (max-width: 1200px) {
        flex-direction: column;
        align-items: center;
      }
    }
    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
  &-btn {
    padding-top: 17px;
    button {
      padding: 11px 20px 12px;
      font-weight: 300;
      font-size: 15px;
      line-height: 12px;
      color: $effervescent-blue;
      border: 1px solid $effervescent-blue;
      border-radius: 1000px;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      &:hover {
        background-color: $effervescent-blue;
        color: $white;
      }
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }
  @media (max-width: 1200px) {
    margin-bottom: 0;
    padding-bottom: 25px;
    border-bottom: 1px solid $desired-down;
    margin-left: -15px;
    margin-right: -15px;
  }
  .graduated-message {
    font-family: Poppins;
    font-size: 16px;
    // font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-bottom: 8px;
    a {
      font-family: "Sofia Pro Light Az", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
      text-decoration: underline!important;
      color: #063B7D;
      /* Inside auto layout */
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
}
