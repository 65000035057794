@import "../../../../../../assets/scss/variables.scss";

.note {
  padding-top: 20px;
  padding-right: 10px;

  &-header {
    margin-bottom: 5px;
    .date {
      column-gap: 11px;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $troller-gray;
      & > div {
        width: 15px;
        display: none;
        &.active {
          display: block;
        }
      }
    }
    .action {
      display: flex;
      align-items: center;
      button {
        min-height: 20px;
        column-gap: 3px;
        span {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background-color: #c4c4c4;
        }
      }
    }
  }
  section {
    display: flex;
    flex-direction: column;
  }
  &-body {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: $beige;
    font-weight: 400;
    font-size: 14px;
    color: $rich-black;
    font-family: "Poppins", sans-serif;

    p {
      margin-bottom: 0;
    }
    p:not(:first-child) {
      margin-top: 10px;
    }
    ol {
      padding-left: 20px;
      margin-bottom: 0;
    }
    ul {
      padding-left: 20px;
      li {
        position: relative;
        &::before {
          content: "";
          width: 4px;
          height: 4px;
          background-color: $rich-black;
          border-radius: 50%;
          position: absolute;
          left: -10px;
          top: 8px;
        }
      }
    }
  }
}
