@import "../../assets/scss/variables.scss";

.pass-success-box {
  .pass-success-ico {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $black;
    border-radius: 50%;
    margin-bottom: 34px;
    i {
      font-size: 16px;
      color: $black;
    }
  }

  .header {
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: $maastright-blue;
    max-width: 450px;
    margin-bottom: 0;
  }
  .header-subtitle {
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    margin: 25px 0;
  }
  .button-row {
    margin-bottom: 50px;
    button {
      max-width: 280px;
      margin: 0 auto;
    }
  }
  .social-list {
    column-gap: 20px;
    .link {
      &:hover {
        text-decoration: none;
      }
      i {
        font-size: 22px;
      }
    }
  }
}
