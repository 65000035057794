@import "../../../../../../assets/scss/variables.scss";

.notes {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  // justify-content: space-between;
  padding-bottom: 88px;
  position: relative;

  .create-note {
    position: fixed;
    bottom: 14px;
    width: 355px;
    padding-left: 28px;
    right: 0;
    border-top: 1px solid #ededed;

    .save-button {
      position: fixed;
      bottom: 14px;
      right: 0;
      width: 75px;
      height: 39px;
      background: #042b59;
      border: none;
      outline: none;
      border-radius: 20px 0 0 20px;
      color: white;
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        background-color: #053e80;
      }

      &:disabled {
        background-color: #717f8fab;
      }
    }
  }

  .header {
    margin-bottom: 5px;
    p {
      margin-bottom: 0;
    }
    button {
      padding: 15px;
      font-weight: 500;
      font-size: 15px;
      line-height: 12px;
      color: $effervescent-blue;
      border: 1px solid $effervescent-blue;
      border-radius: 1000px;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      &:hover {
        background-color: $effervescent-blue;
        color: $white;
      }
    }
  }
  &-wrapp {
    overflow-y: auto;
    padding-bottom: 30px;
    div + button {
      width: fit-content;
      align-self: flex-end;
      background-color: transparent;
      cursor: pointer;
      border: 1px solid $effervescent-blue;
      outline: none;
      font-weight: 300;
      font-size: 15px;
      line-height: 12px;
      border-radius: 1000px;
      padding: 11px 20px 12px;
      margin-top: 4px;
      color: $effervescent-blue;
      transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
      &:focus {
        outline: 0;
      }
      &:hover {
        color: #fff;
        background-color: $effervescent-blue;
      }
    }
  }
}

.delete-note-success {
  background-color: #ffe4e4 !important;
  color: #af4444 !important;
}
.add-note-success {
  background-color: #dbf8e8 !important;
  color: #219251 !important;
}
