@import "../../assets/scss/variables.scss";

.img-box {
  position: relative;
  max-width: 100px;
  width: 100%;
  & > div {
    border: 1px solid $black;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
      background-color: #ecedf1;
    }
  }
  &::after {
    content: "";
    position: absolute;
    width: inherit;
    height: 100%;
    border-radius: 50%;
    background-color: $orange-yellow;
    top: 5px;
    left: 6px;
    z-index: -1;
  }
}
