@import "../../assets/scss/variables.scss";

.privacy-and-policy {
  margin: 30px 0;
}
.font-weight-bold {
  font-weight: bold;
}

.font-style-italic {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: italic;
}