@import "../../assets/scss/variables.scss";

.search-container {
  display: flex;
  // border: 1px solid #000000;
  box-sizing: border-box;
  // padding: 25px;
  margin-bottom: 15px;

  .search-input {
    flex: auto;
    column-gap: 15px;
    margin-right: 30px;
    border: 1px solid #949494;
    border-radius: 12px;
    padding-left: 15px;
    i {
      font-size: 24px;
    }
    input {
      width: 100%;
      border: none;
      outline: none;
    }
  }
  .search-button-wrapper {
    .search-button {
      height: 54px;
      width: 156px;
      min-width: 156px;
      &::before {
        display: none;
      }
    }
  }

}

.search-title {
  font-size: 40px;
  font-weight: 400;
}
.control-actions {
  display: flex;
  align-content: end;
  justify-content: end;
  .filters {
    font-size: 18px;
    display: flex;
    color: #000;
    align-items: center;
    button {
      background: none;
      border: none;
      padding: 0 0 0 8px;
      i {
        font-size: 30px;
      }
    }
  }
  .view-box {
    cursor: pointer;
    column-gap: 11px;
    margin-left: 35px;
    font-size: 18px;
  }
}
.popover {
  z-index: 9;
  margin-top: 10px;
  border: 1px solid #000;
  border-radius: 12px;
  padding: 32px 42px 32px 42px;
  background-color: #fff;
  // width: 100vw;
}
.tag-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 64px;
  gap: 10px;
  justify-content: space-between;
  .filter-title {
    color: #000;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;
    display: block;
  }
  .tag-block {
    flex-basis: 33%;
  }
}
.tag_list {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
  li {
    display: flex;
    background-color: $beige;
    padding: 5px 10px;
    border-radius: 5px;
    span {
      font-size: 14px;
      &.close {
        line-height: 90%;
        font-size: 22px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
}
