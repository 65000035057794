@import "../../../../assets/scss/variables.scss";

.pass-success-box {
  margin-top: 110px;
  @media (max-width: 991px) {
    margin-top: 0;
  }
  .pass-success-ico {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $black;
    border-radius: 50%;
    margin-bottom: 34px;
    i {
      font-size: 16px;
      color: $black;
      @media (max-width: 500px) {
        color: $orange-yellow;
      }
    }
  }

  .header {
    font-size: 42px;
    line-height: 52px;
    text-align: center;
    color: $maastright-blue;
    max-width: 450px;
    margin-bottom: 25px;
    @media (max-width: 500px) {
      font-size: 26px;
      line-height: 39px;
      margin-bottom: 15px;
    }
  }
  .header-subtitle {
    font-weight: 300;
    font-size: 15px;
    line-height: 25px;
    text-align: center;
    margin: 0 auto 25px;
    max-width: 520px;
  }
  .button-row {
    margin-bottom: 25px;
    button {
      width: 270px;
      margin: 0 auto;
    }
    @media (max-width: 500px) {
      margin-top: 15px;
    }
  }
  .bottom-link {
    margin-bottom: 50px;
    a {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: $effervescent-blue;
      &:last-child {
        display: none;
      }
      @media (max-width: 500px) {
        &:first-child {
          display: none;
        }
        &:last-child {
          display: block;
        }
      }
    }
  }
  .social-list {
    column-gap: 20px;
    .link {
      &:hover {
        text-decoration: none;
      }
      i {
        font-size: 22px;
      }
    }
    @media (max-width: 500px) {
      display: none !important;
    }
  }
}

.login-wrapp {
  display: flex;
  column-gap: unquote("min(100px, 3vw)");
  .login-decor {
    min-height: 810px;
    padding-top: 90px;
    overflow: hidden;
    flex: 1;
  }
  @media (max-width: 991px) {
    display: block;
    padding: 40px 25px 50px;
  }
}
