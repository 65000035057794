@import "../../assets/scss/variables.scss";

.about-page {
  padding-top: 75px;
  padding-bottom: 100px;
  .about-top-text {
    h1 {
      text-align: center;
      .colored-title-text {
        color: $orange-yellow;
      }
      @media (max-width: 500px) {
        font-size: 26px;
        line-height: 39px;
        margin-bottom: 15px;
      }
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      max-width: 743px;
      margin: 0 auto;
      @media (max-width: 500px) {
        font-size: 15px;
        line-height: 25px;
        padding: 0;
      }
    }
  }
  .about-pictures {
    padding-top: 20px;
    & > div {
      max-width: 795px;
      min-height: 280px;
      align-items: flex-end;
      margin: 0 auto;
      background-image: url("../../assets/images/about-bg.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      .circle-img {
        position: relative;
        width: 213px;
        height: 213px;
        flex-shrink: 0;
        img {
          display: block;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          height: 100%;
          background-color: #ecedf1;
        }
        &::after {
          content: "";
          width: inherit;
          height: inherit;
          position: absolute;
          top: -8px;
          left: -10px;
          border-radius: 50%;
          border: 1px solid $black;
          z-index: -1;
        }
        @media (max-width: 768px) {
          width: 92px;
          height: 92px;
          &::after {
            top: -4px;
            left: -5px;
          }
        }
      }
      .triangle-img {
        align-self: flex-start;
        text-align: center;
        position: relative;
        display: flex;
        margin: 0;
        max-width: 205px;
        height: 187px;
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          -webkit-clip-path: url(#triangle);
          clip-path: url(#triangle);
          @media (max-width: 768px) {
            -webkit-clip-path: url(#triangleMob);
            clip-path: url(#triangleMob);
          }
        }
        &::after {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='205' height='187' viewBox='0 0 205 187' fill='none'%3E%3Cpath d='M75.0625 10.9359C82.0675 -2.35627 100.841 -3.06583 108.829 9.65965L200.919 156.358C209.351 169.79 199.075 187.134 183.244 186.191L18.9979 176.412C4.85001 175.569 -3.70178 160.393 2.90598 147.855L75.0625 10.9359Z' stroke='black'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 9px;
          left: 14px;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
        @media (max-width: 768px) {
          height: 86px;
          width: 94px;
          &::after {
            top: 4px;
            left: 6px;
          }
        }
      }
      .border-img {
        text-align: center;
        position: relative;
        display: flex;
        margin: 0 0 7px;
        max-width: 207px;
        height: 218px;
        width: 100%;
        img {
          width: 100%;
          object-fit: cover;
          object-position: -95px;
          -webkit-clip-path: url(#border);
          clip-path: url(#border);
          @media (max-width: 768px) {
            object-position: -35px;
            -webkit-clip-path: url(#borderMob);
            clip-path: url(#borderMob);
          }
        }
        &::after {
          content: "";
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='202' height='206' viewBox='0 0 202 206' fill='none'%3E%3Cpath d='M189.836 53.6797C186.858 31.0606 168.805 13.4069 146.125 10.934L55.8385 1.08978C26.5502 -2.10363 0.973221 20.8362 0.973221 50.2981V155.936C0.973221 185.857 27.3149 208.938 56.9771 205.007L158.113 191.602C185.198 188.012 204.253 163.156 200.685 136.068L189.836 53.6797Z' stroke='black'/%3E%3C/svg%3E");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: 3px;
          left: -7px;
          width: 100%;
          height: 100%;
          z-index: -1;
        }
        @media (max-width: 768px) {
          height: 95px;
          width: 100px;
          &::after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='94' height='97' viewBox='0 0 94 97' fill='none'%3E%3Cpath d='M86.2963 19.4541C85.4238 12.8283 80.1357 7.657 73.4921 6.93263L17.5349 0.831431C8.95547 -0.104011 1.46324 6.61574 1.46324 15.246V81.0779C1.46324 89.8428 9.1795 96.6038 17.8684 95.4522L80.5964 87.1382C88.5304 86.0866 94.112 78.8057 93.0671 70.8708L86.2963 19.4541Z' stroke='black'/%3E%3C/svg%3E");
            top: 7px;
            left: -8px;
          }
        }
      }
      @media (max-width: 768px) {
        min-height: 127px;
      }
    }
    @media (max-width: 500px) {
      padding-bottom: 15px;
    }
  }
  .about-questions {
    .about-question {
      margin-top: 75px;
      column-gap: 30px;
      @media (max-width: 500px) {
        flex-direction: column;
        margin-top: 35px;
        row-gap: 25px;
      }
      &-img {
        height: 75px;
        background: $beige;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 75px;
        @media (max-width: 500px) {
          width: 75px;
        }
      }
      &-text {
        h4 {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: $maastright-blue;
        }
        p {
          font-weight: 300;
          font-size: 15px;
          line-height: 25px;
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 500px) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
