@import "../../../../assets/scss/variables.scss";

.right-sidebar {
  border-left: 1px solid $white-edgard;
  padding-top: 81px;
  margin-top: -81px;
  width: 355px;
  display: flex;
  // position: relative;
  &-top {
    padding: 40px 38px 40px 40px;
    min-height: 100%;
    width: 100%;
  }
  &-component {
  }
}
