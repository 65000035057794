@import "../../../../assets/scss/variables.scss";

.container {
  scroll-margin-top: 180px;
}
.title-box {
  max-width: 1050px;
  margin: 0 auto 40px;
  h2 {
    margin-bottom: 0;
  }
  .sort {
    cursor: pointer;
    span {
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      color: $black;
    }
    .down {
      margin-top: 3px;
    }
    .up {
      transform: rotate(180deg);
      align-self: flex-end;
      margin-bottom: 3px;
    }
    i {
      display: block;
      font-size: 8px;
    }
  }
}
.topics-items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
  padding-bottom: 40px;
  max-width: 1050px;
  margin: 0 auto;
}
.topic-wrapp {
  flex: 0 1 calc(33.3% - 14px);
  @media (max-width: 1700px) {
    flex: 0 1 calc(50% - 10px);
  }
  @media (max-width: 1400px) {
    flex: 1 1 100%;
    max-width: 336px;
    margin: 0 auto;
  }
}
