.embedVideo {
  height: 200px;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.embedYoutube {
  height: 280px;

  @media (max-width: 1550px) {
    height: 200px;
  }
}