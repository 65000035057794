@import "../../assets/scss/variables.scss";

.large-banner {
    background-color: $maastright-blue;
    border-radius: 15px;
    margin-bottom: 40px;
    color: $white;
    display: flex;
    column-gap: 16px;
    .left-content {
        z-index: 0;
        width: 50%;
        background-color: $cta-foreground;
        border-radius: 3% 11% 11% 3% / 3% 35% 35% 3%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 10px;
        @media (min-width: 1428px) {
            padding: 40px 10px;
        }
        .content-box {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            > p {
                font-family: 'Poppins', sans-serif;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                margin: 10px 0 10px;
                @media (min-width: 1428px) {
                    font-size: 24px;
                    line-height: 36px;
                }
            }
            a {
                margin-right: 48px;
            }
            button {
                margin-bottom: 20px;
                border: 1px solid $black;
                p {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 18px;
                }
            }
            span {
                font-weight: 300;
                font-size: 13px;
                font-family: Poppins,sans-serif;
                a {
                    margin-right: 0;
                    font-weight: 700;
                    color: $white;
                }
            } 
        }
    }
    .right-content {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        img {
            padding: 32px;
            @media (min-width: 1428px) {
                padding: 48px;
            }
        }
    }
}

.small-banner {
    background-color: $maastright-blue;
    border-radius: 15px;
    margin: 40px 0;
    color: $white;
    display: flex;
    flex-direction: column;
    column-gap: 40px;
    justify-content: space-around;
    align-items: center;
    padding: 38px 25px;
    @media (min-width: 1280px) {
        flex-direction: row;
    }
    .left-content {
        width: 56%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (min-width: 1280px) {
            align-items: flex-start;
        }
        > p {
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            margin: 10px 0 0;
            text-align: center;
            @media (min-width: 1280px) {
                font-size: 24px;
                line-height: 36px;
                margin: 10px 0 10px;
                text-align: left;
            }
        }
    }
    .right-content {
        width: 220px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        z-index: 0;
        button {
            margin: 24px 0 30px;
            @media (min-width: 1280px) {
                margin: 0 0 20px;
                border: 1px solid $black;
                // margin-bottom: 16px;
            }
            p {
                font-weight: 300;
                font-size: 18px;
                line-height: 18px;
            }
        }
        span {
            font-weight: 300;
            font-size: 13px;
            font-family: Poppins,sans-serif;
            > a {
                font-weight: 700;
                color: $white;
            }
        }           
    }
}