@import "../../assets/scss/variables.scss";

.pagination {
  margin-bottom: 30px;
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  button {
    padding: 5px;
    cursor: pointer;
    &:disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
    &.prev {
      transform: rotate(180deg);
    }
  }
  i {
    font-size: 10px;
    &::before {
      color: $black;
    }
  }
  .arrow-box {
    display: flex;
    column-gap: 5px;
  }

  ul {
    display: flex;
    align-items: baseline;
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: calc(100vw - 800px);
    li {
      cursor: pointer;
      color: $rich-black;
      font-size: 14px;
      line-height: 14px;
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      &.active {
        color: $white;
        font-weight: 700;
        font-size: 16px;
        background-color: $maastright-blue;
      }
    }
  }
}
