/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans:ital,wght@0,1..1000;1,1..1000&display=swap');
@import "./assets/scss/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300&display=swap");
@import url("./assets/fonts/icon-font.scss");

@font-face {
  font-family: "icomoon";
  src: url("./assets/fonts/icomoon.eot?cod02t");
  src: url("./assets/fonts/icomoon.eot?cod02t#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/icomoon.ttf?cod02t") format("truetype"),
    url("./assets/fonts/icomoon.woff?cod02t") format("woff"),
    url("./assets/fonts/icomoon.svg?cod02t#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Sofia Pro Light Az";
  font-style: normal;
  font-weight: 200;
  src: local("Sofia Pro Light Az"), url("./assets/fonts/Sofia Pro Light Az.woff") format("woff"), url("./assets/fonts/Sofia Pro Light Az.otf") format("opentype");
}

body {
  font-family: "Sofia Pro Light Az", sans-serif;
}

.page {
  min-height: calc(100vh - 181px);
}
img {
  max-width: 100%;
}

.mobile-container {
  @media (max-width: 768px) {
    .row:not(.bs-row) {
      margin-left: -5px;
      margin-right: -5px;
    }
    padding-left: 25px;
    padding-right: 25px;
  }
}

#root {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // selects the main content section and positions it BELOW the fixed navbar
  > div:first-of-type {
    margin-top: 81px;
  }
}

header + div {
  flex-grow: 1;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  &:hover {
    text-decoration: none;
  }
}

.orange-yellow-color {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  background-image: url(assets/images/text-bg.svg);
  background-color: $orange-yellow;
  background-position: center;
  background-size: 620px 230px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Poppins", sans-serif;
  color: $maastright-blue;
}

h1,
h2,
h3 {
  font-weight: 700;
}

.normalize-btn {
  border: 0;
  outline: 0;
  background-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  &:focus {
    outline: 0;
  }
}

.orange-after-box {
  position: relative;
  border: 1px solid $black;
  border-radius: 25px;
  background-color: $white;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 20px);
    height: 45px;
    background-color: $orange-yellow;
    border-radius: 25px;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    z-index: -1;
  }
}

.blue-icon-btn {
  padding: 11px 20px 12px;
  font-weight: 300;
  font-size: 15px;
  line-height: 12px;
  column-gap: 10px;
  background-color: $effervescent-blue;
  color: $white;
  border-radius: 1000px;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  i {
    font-size: 17px;
  }
  &:hover {
    color: $white;
  }
  .chevron-wrapp {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border: 1px solid $white;
    border-radius: 50%;
    i {
      font-size: 6px;
    }
  }
  @media (max-width: 1200px) {
    padding: 6px 11px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    i {
      font-size: 16px;
    }
  }
}

.custom-container {
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto;
}

.red-icon::before {
  color: $red;
}

.create-note {
  .quill {
    display: flex;
    flex-direction: column;

    .ql-toolbar.ql-snow {
      order: 2;
      border: none;
    }
    .ql-container.ql-snow {
      height: 71px;
      border: none;
    }
    .ql-editor.ql-blank::before {
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: $troller-gray;
    }
  }
}

a {
  text-decoration: none !important;
}