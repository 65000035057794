@import "../../../../../../assets/scss/variables.scss";

.inner-page-header {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 325px;
  padding-bottom: 35px;
  background-color: $white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: -40px -3.4vw 25px;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: calc(100% - 20px);
    height: 45px;
    background-color: $orange-yellow;
    border-radius: 25px;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    z-index: -1;
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, rgba(2, 28, 60, 0) 0%, #021c3c 100%);
    opacity: 0.5;
  }
  & > div {
    flex-grow: 1;
    column-gap: 10px;
    position: relative;
    z-index: 1;
    padding: 0 unquote("min(100px, 3vw)");
  }
  .icon-title {
    column-gap: 16px;
    i {
      margin-top: 10px;
    }
    h2,
    p {
      color: $white;
    }
  }
  .actions {
    column-gap: 15px;
    flex-shrink: 0;
    padding-top: 10px;
    div, a {
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: $white;
      cursor: pointer;
      &.speaker-save {
        padding: 12px 20px 11px;
        column-gap: 10px;
        border: 1px solid $white;
        border-radius: 1000px;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
        i {
          font-size: 17px;
        }
        &.saved,
        &:hover {
          background-color: $effervescent-blue;
          color: $white;
        }
      }
    }
  }
}
