@import "../../../assets/scss/variables.scss";

.inputContainer {
  display: flex;
  position: relative;
  width: 100%;
  padding-bottom: 0;
  flex-direction: column;
  text-align: left;

  & > div {
    display: flex;
  }

  label {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 23px;
    color: $black;
    order: 2;
    margin-bottom: 0;
  }
}

.customRadio {
  width: 20px;
  height: 20px;
  order: 1;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid $black !important;
  box-sizing: border-box;
  background: $white !important;
  position: relative;
  border-radius: 1000px;
  cursor: pointer;
  margin-right: 10px;
  input {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    display: block;
    width: 14px;
    cursor: pointer;
    height: 14px;
    border-radius: inherit;
    appearance: none;
    &:checked {
      background-color: $black !important;
    }
  }
}