@import "../../../../assets/scss/variables.scss";

.profile-payments {
  .back-box {
    display: none;
    margin: 12px 0 30px;
    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      border: 1px solid $black;
      border-radius: 50%;
      i {
        transform: rotate(180deg);
        &::before {
          color: $black;
        }
      }
    }
    @media (max-width: 1200px) {
      display: block;
    }
  }
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    margin-bottom: 25px;
    .request-box {
      column-gap: 15px;
      p {
        font-style: italic;
        font-weight: 300;
        font-size: 13px;
        line-height: 24px;
        margin-bottom: 0;
      }
      @media (max-width: 1200px) {
        flex-direction: column-reverse;
        text-align: center;
        row-gap: 10px;
      }
    }
    .total-box {
      h3 {
        font-size: 30px;
        line-height: 45px;
        color: $maastright-blue;
        margin-bottom: 5px;
        @media (max-width: 1200px) {
          font-size: 20px;
          line-height: 27px;
        }
      }
      p {
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      text-align: center;
      row-gap: 10px;
    }
    @media (max-width: 1200px) {
      margin: 0 -15px;
      padding-bottom: 25px;
      border-bottom: 1px solid $desired-down;
    }
  }
  @media (max-width: 1200px) {
    margin-top: -40px;
  }
  .disabled {
    opacity: 0.5;
  }
}
