@import "../../../../assets/scss/variables.scss";

.featured {
  h2 {
    margin-bottom: 0;
  }
  .featured-header {
    margin-bottom: 25px;
  }
  .slider-top-box {
    position: relative;
    .btn-wrap {
      position: absolute;
      right: 0;
      bottom: -30px;
      display: inline-flex;
      align-items: center;
      column-gap: 14px;
      button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        font-size: 12px;
        i:before {
          color: $black;
        }
        &.prev {
          transform: rotate(180deg);
        }
      }
    }
    .dots-list {
      padding: 0;
      margin: 0;
      display: flex;
      width: calc(100% - 80px);
      padding-left: 20px;
      li {
        height: 1px;
        margin: 0;
        background-color: $white-edgard;
        flex: 1;
        &:global(.slick-active) {
          height: 3px;
          margin-top: -1px;
          border-radius: 3px;
          background-color: $orange-yellow;
        }
      }
    }
  }

  .featured-topic-box {
    margin-top: 85px;
    margin-bottom: 30px;
    .header {
      margin-bottom: 25px;
      h3 {
        font-size: 26px;
        line-height: 39px;
        margin-bottom: 0;
      }
      a {
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: $effervescent-blue;
      }
      button {
        min-width: 250px;
      }
    }
  }
}
