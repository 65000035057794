@import "../../../../../../assets/scss/variables.scss";

.img-box {
  flex-shrink: 0;
  position: relative;

  .file-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 22;
    appearance: none;
    cursor: pointer;
  }

  .img-radius {
    text-align: center;
    position: relative;
    display: flex;
    margin: 0 auto;
    max-width: 175px;
    height: 185px;
    width: 100%;
    img {
      width: 100%;
      object-fit: cover;
      -webkit-clip-path: url(#profile);
      clip-path: url(#profile);
      &.edit {
        filter: brightness(0.5);
      }
    }
    &::after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='170' height='178' viewBox='0 0 170 178' fill='none'%3E%3Cpath d='M156.246 34.4441C154.777 23.2473 145.845 14.5049 134.62 13.2763L27.6651 1.57045C13.1655 -0.0164863 0.499527 11.3388 0.499527 25.925V152.516C0.499527 167.33 13.5455 178.755 28.2306 176.802L148.163 160.845C161.559 159.063 170.982 146.771 169.224 133.373L156.246 34.4441Z' stroke='%23052B5A'/%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 2px;
      left: -6px;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .edit-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: $white;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-shadow: #000000 1px 0 10px;
      p {
        margin-bottom: 0;
        font-weight: 300;
        text-align: left;
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 5px;
        &:last-child {
          font-style: italic;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
          max-width: 110px;
          span {
            font-weight: 500;
          }
        }
      }
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }
  .img-edit-mobile {
    position: relative;
    width: 93px;
    display: none;
    
    button {
      position: absolute;
      right: -6px;
      top: 0;
      width: 29px;
      height: 29px;
      border-radius: 50%;
      background-color: $red;
      display: none;
      @media (max-width: 1200px) {
        display: inline-flex;
      }
    }
    @media (max-width: 1200px) {
      display: block;
    }
  }
}
