.filter-icon {
  svg {
    max-width: 100%;
    .filter-icon-bg {
      fill: transparent;
      stroke: #000;
    }
    .filter-icon-center {
      fill: #000;
      stroke: #000;
    }
  }
  .active {
    svg {
      .filter-icon-bg {
        fill: #fcb912;
        stroke: #fcb912;
      }
      .filter-icon-center {
        fill: #fff;
        stroke: #fff;
      }
    }
  }
}
