@import "../../../../assets/scss/variables.scss";

.saved-podcasts-table {
  // max-width: 1050px;
  margin: 0 auto;
  td,
  th {
    font-weight: 400;
    padding: 20px 5px;
    font-size: 15px;
    line-height: 24px;
    border-color: $white-edgard;
    vertical-align: top;
  }
  th:first-child,
  td:first-child {
    padding-left: 0;
  }
  th:last-child {
    width: 100px;
  }
  th:last-child,
  td:last-child {
    padding-right: 0;
    text-align: right;
  }
  .level-column-item {
    i {
      font-size: 22px;
    }
  }
  //  .level-column-item ,.date-column-item {
  //     width: 135px;
  // }
  // .topic-column-item {
  //     width: 200px;
  // }
  .saved-podcasts-topic {
    display: inline-flex;
    flex-direction: column;
    max-width: 225px;
    width: 100%;
  }
  thead th {
    font-size: 13px;
    line-height: 13px;
    color: $maastright-blue;
    border-color: $white-edgard;
    border-width: 1px;
    &.filter {
      cursor: pointer;
      min-width: 56px;
      &.prev {
        transform: rotate(180deg);
      }
      i {
        font-size: 7px;
      }
    }
    & > .sort {
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-weight: 300;
      cursor: pointer;
      * {
        pointer-events: none;
      }
      .up {
        transform: rotate(180deg);
        align-self: flex-end;
        margin-bottom: 2px;
      }
      i {
        display: block;
      }
    }
  }
  tbody {
    th {
      font-weight: 600;
      button {
        font-size: 23px;
      }
      div {
        column-gap: 15px;
      }
    }
    td {
      font-weight: 300;
      div {
        column-gap: 30px;
      }
    }
  }
}
