@import "../../../assets/scss/variables.scss";

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  border: 0;
  background-color: $beige;
  border-radius: 5px;

  label {
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    color: $black;
  }
}
.select-box {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 13px;
  line-height: 25px;
  .select-prev-text {
    flex-shrink: 0;
  }
  .select-input {
    display: flex;
    font-weight: 700;
    padding-right: 0;
  }
}