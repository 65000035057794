@import "../../assets/scss/variables.scss";

.search-container {
  display: flex;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 15px;
  border: 1px solid #ededed;
  border-radius: 24px;
  box-shadow: 4px 4px 4px 0px #00000040;
  background-color: #fff;
  max-width: 1000px!important;
  gap: 30px;
  .search-input {
    flex: 1;
    column-gap: 15px;
    padding-left: 16px;
    border: 1px solid #949494;
    border-radius: 12px;
    i {
      font-size: 24px;
    }
    input {
      width: 100%;
      border: none;
      outline: none;
      font-size: 15px;
      &::placeholder {
        color: #949494;
      }
    }
  }
  & > ul {
    display: flex;
    column-gap: 50px;
  }
  .view-box {
    cursor: pointer;
    column-gap: 11px;
    margin-left: 35px;
    font-size: 18px;
  }
  .search-button {
    height: 54px;
  }
  .filters {
    font-size: 18px;
    display: flex;
    color: #000;
    align-items: center;
    button {
      background: none;
      border: none;
      padding: 0 0 0 8px;
      i {
        font-size: 30px;
      }
    }
  }
}
.popover {
  z-index: 9;
  margin-top: 10px;
  border: 1px solid #000;
  border-radius: 12px;
  padding: 32px 42px 32px 42px;
  background-color: #fff;
  // width: 100vw;
}