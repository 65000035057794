[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-white-play .path1:before {
  content: "\e911";
  color: rgb(255, 255, 255);
}
.icon-white-play .path2:before {
  content: "\e93d";
  margin-left: -0.9228515625em;
  color: rgb(0, 0, 0);
}
.icon-full-star {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.icon-full-star:before {
  content: "\e90e";
}
.icon-full-star::after {
  content: "\e908";
  position: absolute;
  display: inline-block;
  left: 1px;
  top: 0;
  color: #fcb912;
  z-index: -1;
}

.icon-map-pin:before {
  content: "\e909";
}
.icon-empty-mic:before {
  content: "\e90a";
  color: #fff;
}
.icon-bookmark-full {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.icon-bookmark-full:before {
  content: "\e905";
}
.icon-bookmark-full:after {
  content: "";
  position: absolute;
  display: inline-block;
  background-image: url("../images/ico/bookmark-full.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 23px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.icon-bookmark-empty:before {
  content: "\e905";
}
.icon-bank:before {
  content: "\e901";
  color: #f51d00;
}
.icon-chevron-right:before {
  content: "\e906";
  color: #fff;
}
.icon-chevron-sown:before {
  content: "\e907";
}
.icon-contact-info:before {
  content: "\e90b";
}
.icon-done:before {
  content: "\e90c";
}
.icon-empty-play:before {
  content: "\e90d";
}
.icon-empty-star:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
  color: #042b59;
}
.icon-featured:before {
  content: url(../images/ico/featured.svg);
}
.icon-play-random::before {
  content: url(../images/ico/play-random.svg);
}
.icon-instagram:before {
  content: "\e912";
  color: #042b59;
}
.icon-list:before {
  content: "\e913";
}
.icon-lock:before {
  content: "\e914";
}
.icon-magen-david:before {
  content: "\e915";
}
.icon-mail:before {
  content: "\e916";
  color: #f51d00;
}
.icon-mentor .path1:before {
  content: "\e917";
  color: rgb(252, 185, 18);
}
.icon-mentor .path2:before {
  content: "\e918";
  margin-left: -1.0224609375em;
  color: rgb(2, 28, 60);
}
.icon-mentor .path3:before {
  content: "\e919";
  margin-left: -1.0224609375em;
  color: rgb(2, 28, 60);
}
.icon-mentor .path4:before {
  content: "\e91a";
  margin-left: -1.0224609375em;
  color: rgb(2, 28, 60);
}
.icon-mentor .path5:before {
  content: "\e91b";
  margin-left: -1.0224609375em;
  color: rgb(255, 248, 232);
}
.icon-mentor .path6:before {
  content: "\e91c";
  margin-left: -1.0224609375em;
  color: rgb(255, 248, 232);
}
.icon-mentor .path7:before {
  content: "\e91d";
  margin-left: -1.0224609375em;
  color: rgb(255, 248, 232);
}
.icon-mic .path1:before {
  content: "\e91e";
  color: rgb(252, 185, 18);
}
.icon-mic .path2:before {
  content: "\e91f";
  margin-left: -0.625em;
  color: rgb(2, 28, 60);
}
.icon-mic .path3:before {
  content: "\e920";
  margin-left: -0.625em;
  color: rgb(2, 28, 60);
}
.icon-minus:before {
  content: "\e921";
}
.icon-notes:before {
  content: "\e922";
}
.icon-noun-audio:before {
  content: "\e923";
}
.icon-noun-podcast:before {
  content: "\e924";
}
.icon-noun-torah:before {
  content: "\e925";
}
.icon-paid .path1:before {
  content: "\e926";
  color: rgb(252, 185, 18);
}
.icon-paid .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(2, 28, 60);
}
.icon-payment-empty:before {
  content: "\e928";
}
.icon-pen .path1:before {
  content: "\e929";
  color: rgb(252, 185, 18);
}
.icon-pen .path2:before {
  content: "\e92a";
  margin-left: -0.75em;
  color: rgb(252, 185, 18);
}
.icon-pen .path3:before {
  content: "\e92b";
  margin-left: -0.75em;
  color: rgb(0, 0, 0);
}
.icon-pen .path4:before {
  content: "\e92c";
  margin-left: -0.75em;
  color: rgb(2, 28, 60);
}
.icon-pin:before {
  content: "\e92d";
  color: #f51d00;
}
.icon-plus:before {
  content: "\e92e";
}
.icon-rocket:before {
  content: "\e92f";
}
.icon-search:before {
  content: "\e930";
  color: #042b59;
}
.icon-series:before {
  content: "\e931";
  color: #042b59;
}
.icon-share:before {
  content: "\e932";
}
.icon-sort:before {
  content: "\e933";
}
.icon-telephone:before {
  content: "\e934";
  color: #f51d00;
}
.icon-topics:before {
  content: "\e935";
  color: #042b59;
}
.icon-user-plus:before {
  content: "\e936";
}
.icon-user:before {
  content: "\e937";
}
.icon-heart-empty:before {
  content: "\e900";
}
.icon-heart-full .path1:before {
  content: "\e902";
  color: rgb(252, 185, 18);
}
.icon-heart-full .path2:before {
  content: "\e93c";
  margin-left: -1.095703125em;
  color: rgb(0, 0, 0);
}
.icon-close::before {
  content: url(../images/ico/close.svg);
  display: inline-block;
}
.icon-linkedin::before {
  content: url(../images/ico/linkedin.svg);
  display: inline-block;
}
.icon-twitter::before {
  content: url(../images/ico/twitter.svg);
  display: inline-block;
}
.icon-edit::before {
  content: url(../images/ico/edit.svg);
  display: inline-block;
}
.icon-save::before {
  content: url(../images/ico/save.svg);
  display: inline-block;
}
.icon-loading::before {
  content: url(../images/ico/loading.svg);
  display: inline-block;
}
.icon-clock::before {
  content: url(../images/ico/clock.svg);
  display: inline-block;
}
.icon-view-chevron::before {
  content: url(../images/ico/view-chevron.svg);
  display: inline-block;
}
.icon-door-hole::before {
  content: url(../images/ico/door-hole.svg);
  display: inline-block;
}
.icon-briefcase::before {
  content: url(../images/ico/briefcase.svg);
  display: inline-block;
}
.icon-antenna::before {
  content: url(../images/ico/antenna.svg);
  display: inline-block;
}
.icon-select::before {
  content: url(../images/ico/select.svg);
  display: inline-block;
}

.icon-grid::before {
  content: url(../images/ico/grid.svg);
  display: inline-block;
}

.icon-copy::before {
  content: url(../images/ico/copy.svg);
  display: inline-block;
}

.icon-notice::before {
  content: url(../images/ico/notice.svg);
  display: inline-block;
}
.icon-filter::before {
  content: url(../images/ico/filter.svg);
  display: inline-block;
}