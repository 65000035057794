@import "../../../../../../assets/scss/variables.scss";

.item {
  padding: 20px 0;
  column-gap: 40px;
  .star-btn {
    font-size: 23px;
    flex-shrink: 0;
    margin-right: 16px;
  }
  .podcast {
    &-title {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      color: $maastright-blue;
      margin-bottom: 5px;
    }
    &-info {
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      color: $black;
      margin-bottom: 0;
    }
  }
  .separator {
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 50%;
    background-color: $bank-vault;
    margin: 0 10px;
  }
  .play-box {
    column-gap: 25px;
    .track-time {
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;
      color: $black;
    }
  }
}
