@import "../../assets/scss/variables.scss";

.rating-inputs-box {
  column-gap: 20px;
  // justify-content: center;
  & > p {
    margin-bottom: 0;
  }
  .rating-inputs {
    display: flex;
    align-items: center;
    column-gap: 15px;
    label {
      display: inline-flex;
      width: 50px;
      height: 50px;
      margin-bottom: 0;
      justify-content: center;
      align-items: center;
      background-color: $beige;
      border-radius: 4px;
      cursor: pointer;
    }
    input {
      display: none;
    }
    input:checked + label {
      background-color: $effervescent-blue;
      color: $white;
    }
  }
}
