@import "../../../../../../assets/scss/variables.scss";

.item {
  display: flex;
  flex-direction: column;
  min-height: 331px;
  width: 100%;
  height: 100%;
  &-header {
    border-radius: 25px 25px 0 0;
    overflow: hidden;
    img {
      display: block;
      height: 155px;
      width: 100%;
      object-fit: cover;
    }
  }
  &-speaker {
    padding-top: 25px;
    img {
      width: 50%;
      margin: 0 auto;
      border-radius: 50%;
    }
  }
  &-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 25px;
    &-name {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 5px;
      color: $maastright-blue;
    }
    &-episode {
      font-weight: 300;
      font-size: 13px;
      line-height: 23px;
      color: $black;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 20px;
    }
    &-link {
      margin-top: auto;
      a {
        padding: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $effervescent-blue;
        font-size: 15px;
        line-height: 15px;
        border-radius: 4px;
        transition: background-color 0.2s ease-in-out;
        border-radius: 10000px;
        &:hover {
          background-color: #216fcb;
        }
      }
      button {
        background-color: transparent;
        border: 0;
        outline: 0;
        font-size: 25px;
        color: $black;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.topic-list-wrapp {
  width: 100%;
  h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
    color: $maastright-blue;
  }
  .description {
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    color: $black;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
  }
  button {
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 25px;
    color: $black;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}