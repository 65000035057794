@import "../../../../assets/scss/variables.scss";

.container {
  scroll-margin-top: 180px;
}
.title-box {
  max-width: 1050px;
  margin: 0 auto 40px;
  .sort {
    cursor: pointer;
    span {
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      color: $black;
    }
    .down {
      margin-top: 3px;
    }
    .up {
      transform: rotate(180deg);
      align-self: flex-end;
      margin-bottom: 3px;
    }
    i {
      display: block;
      font-size: 8px;
    }
  }
}
