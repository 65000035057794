.container {
  scroll-margin-top: 180px;
}
.icon-title {
  column-gap: 16px;
  max-width: 1050px;
  margin: 0 auto 20px;
  h2 {
    margin-bottom: 0;
  }
}
