@import "../../../../../assets/scss/variables.scss";

.notes {
  &:not(:first-child) {
    margin-top: 20px;
  }
  p,
  li {
    font-size: 14px;
    line-height: 21px;
  }
  &-time {
    color: $troller-gray;
    margin-bottom: 10px;
  }
  &-text {
    display: inline-block;
    padding: 8px 10px;
    color: $rich-black;
    border-radius: 5px;
    background-color: $beige;
    p {
      margin-bottom: 0;
    }
    ul {
      list-style: disc;
      list-style-position: inside;
    }
  }
}
