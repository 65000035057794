@import "../../assets/scss/variables.scss";

.donate-page {
  position: relative;
  min-height: 670px;

  .left-section {
    padding: 75px 0;
  }

  .right-img-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    img {
      max-height: 100%;
    }

    &::before {
      content: "";
      background-image: url("../../assets/images/slider-home-bg.svg");
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: -140px;
      width: 190px;
      height: 200px;
      z-index: -1;
      top: 30px;
    }

    &::after {
      content: "";
      background-image: url("../../assets/images/slider-home-bg.svg");
      //background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      left: -120px;
      width: 190px;
      height: 200px;
      z-index: -1;
      bottom: 50px;
    }
  }

  .prices {
    width: 367px !important;
    max-height: 600px;
    overflow-y: auto;
    margin: 0 auto;
    position: absolute;
    left: calc(100vw - 84%);
    top: 10%;

    @media (max-width: 1600px) {
      left: calc(100vw - 67%);
    }

    iframe {
      z-index: 1 !important;
    }
  }
  .top-text-box {
    max-width: 440px;
    margin-bottom: 50px;
    padding-top: 60px;
    h1 {
      line-height: 62px;
      margin-bottom: 25px;
      color: $maastright-blue;
      @media (max-width: 768px) {
        font-size: 26px;
        line-height: 39px;
        margin-bottom: 15px;
        br {
          display: none;
        }
        .colored-title-text {
          color: $maastright-blue;
        }
      }
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 25px;
      &.top-text-descr {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 5px;
      }
      @media (max-width: 768px) {
        font-size: 16px;
        margin-bottom: 10px;
        &.top-text-descr {
          margin-bottom: 0;
        }
      }
    }
  }
  .top-img-box {
    position: relative;
    right: calc(100vw - 320%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    padding: 30px 0;

    .img-radius {
      text-align: center;
      position: relative;
      display: flex;
      max-width: 300px;
      width: 100%;
      img {
        width: 100%;
        object-fit: cover;
        -webkit-clip-path: url(#imgTop);
        clip-path: url(#imgTop);
        @media (max-width: 991px) {
          -webkit-clip-path: url(#imgTopMob);
          clip-path: url(#imgTopMob);
        }
      }
      @media (max-width: 991px) {
        height: 310px;
        width: 295px;
      }
    }
    & > img {
      position: absolute;
      max-width: none;
      width: 130%;
      max-height: 385px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      @media (max-width: 1200px) {
        max-height: 235px;
      }
    }
  }

  .button-right-text {
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    height: 100%;
    margin-bottom: 0;
    padding-left: 10px;
    align-items: center;
    color: $maastright-blue;
  }

  .pacage {
    padding-top: 80px;
    &-number {
      font-weight: 300;
      font-size: 13px;
      line-height: 24px;
      margin-bottom: 10px;
    }
    &-raiting {
      display: flex;
      justify-content: center;
      align-items: center;
      width: max-content;
      margin: 0 auto 30px;
      padding: 0 13px;
      height: 50px;
      border: 1px solid $black;
      border-radius: 1000px;
      column-gap: 12px;
      i {
        font-size: 20px;
      }
    }
    &-name {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: $maastright-blue;
      margin-bottom: 10px;
    }
    &-descr {
      font-weight: 300;
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 0;
      padding: 0 16px;
    }
    &-price {
      padding: 20px 0;
      margin: 25px 0;
      border-top: 1px solid $gray;
      border-bottom: 1px solid $gray;
      h5 {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        color: $maastright-blue;
      }
      p {
        margin-bottom: 0;
        font-size: 15px;
        line-height: 25px;
        font-weight: 300;
        strong {
          font-weight: 400;
        }
      }
    }
    .button-descr {
      margin-top: 24px;
    }
    button {
      max-width: 256px;
      margin: 0 auto;
    }
    @media (max-width: 768px) {
      padding-top: 50px;
      .pacage-item {
        margin-bottom: 50px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 25px;
    padding-bottom: 0;
  }
  @media (max-width: 991px) {
    & .row-reverse {
      .button-right-text {
        margin-top: 31px;
        justify-content: center;
        height: auto;
      }
      button {
        max-width: 256px;
        margin: 0 auto;
      }
    }
  }
}
