@import "../../assets//scss/variables.scss";

.drop-down {
  position: relative;
  section {
    display: flex;
  }
  button {
    z-index: -1;
  }
  ul {
    position: absolute;
    right: 0;
    top: 120%;
    width: 150px;
    height: max-content;
    padding: 25px;
    text-align: left;
    border-radius: 10px;
    border: 1px solid $white-edgard;
    background-color: $white;
    li {
      font-weight: 400;
      font-size: 15px;
      line-height: 15px;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 25px;
      }
      a {
        color: $black;
      }
    }
  }
}
