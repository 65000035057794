.title {
  margin-bottom: 24px;
  font-size: 42px;
  line-height: 52px;
  @media (max-width: 991px) {
    text-align: center;
    font-size: 26px;
    line-height: 39px;
    margin-bottom: 15px;
  }
}
.description {
  margin-bottom: 58px;
  font-weight: 300;
  font-size: 18px;
  padding-right: 12px;
  
  @media (max-width: 991px) {
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 30px;
  }
}
.btn-wrap {
  width: 100%;
  display: inline-block;
  margin-right: 25px;
  @media (max-width: 991px) {
    display: none;
  }
}
.btn-wrap-mobile {
  display: none;
  width: 100%;
  @media (max-width: 991px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.prev,
.next {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border: 1px solid #021c3c;
  background: transparent;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  position: relative;
  & > i::before {
    color: #021c3c;
  }
  &:focus {
    outline: 0;
  }
}
.prev {
  margin-right: 25px;
  i {
    transform: rotate(180deg);
  }
}

.wrap-item {
  max-width: 305px;
  cursor: pointer;
}

.wrap-item-img {
  margin-bottom: 25px;
  & > div {
    position: relative;
    width: 295px;
    height: 195px;
    display: flex;
    margin-left: auto;
    img {
      width: 100%;
      -webkit-clip-path: url(#sliderFirst);
      clip-path: url(#sliderFirst);
    }
    &::after {
      content: "";
      display: block;
      width: 90%;
      height: 95%;
      border: 1px solid #021c3c;
      border-radius: 25px;
      position: absolute;
      left: -8px;
      top: 17px;
      z-index: -1;
    }
  }
}

.item-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -5px;
  left: -5px;
}

.item-title {
  color: #021c3c;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins";
  margin-bottom: 10px;
}

.item-description {
  font-size: 15px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
