@import "../../assets/scss/variables.scss";

.drop-down {
  position: relative;
  z-index: 2;
  section {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }
  .drop-down-wrapp {
    position: absolute;
    left: 0px;
    top: calc(100% + 15px);
    display: block;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid $black;
    background-color: $white;
    width: 300px;
    padding: 15px 0;
  }
  ul {
    overflow-y: scroll;
    height: max-content;
    max-height: 400px;
    padding: 10px 25px;
    text-align: left;
    li {
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      & > div {
        font-weight: 300;
        font-size: 14px;
        line-height: 14px;
        color: $black;
        column-gap: 10px;
        cursor: pointer;
        &.active {
          font-weight: 500;
        }
        .checkbox {
          cursor: pointer;
          position: relative;
          flex-shrink: 0;
          label {
            margin-bottom: 0;
          }
          input {
            position: absolute;
            z-index: -2;
            opacity: 0;
            visibility: hidden;
          }
        }
        .filter-text {
          padding-top: 5px;
        }
      }
    }
  }
}
ul::-webkit-scrollbar-button {
  display: none;
}
ul::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 3px 0 0 3px;
}
ul::-webkit-scrollbar-track-piece {
  background-color: $white;
}
ul::-webkit-scrollbar {
  width: 3px;
}
