@import "../../assets/scss/variables.scss";

.trivia-page {  
  height: calc(100vh - 81px - 106px);
  width: 100%;
  overflow: hidden;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  align-self: center;

  .content-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    
    @media (max-width: 500px) {
      flex-direction: column; // stack on mobile
    }
  }
  
  .title-container {
    height: auto;
    flex: 2;
    display: flex;
    align-items: center;

    .title-text {
      text-align: left;

      @media (max-width: 500px) {
        text-align: center;
        margin-bottom: 20px; // Space between the title and the form
      }
    }
  } 

  .trivia-iframe {
    flex: 5;
    height: auto;
    min-height: 50vh;
    width: 100%;
    border: none;
    margin: 12px 0;

    @media (max-width: 500px) {
      order: -1; // Bring the iframe above the text on mobile
      min-height: 60vh;
    }
  }

  @media (min-width: 1200px) {
    max-width: 1170px;
    padding: 0;
  }
}