@import "../../../../../../assets/scss/variables.scss";

.featured-items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 40px;
  padding-bottom: 40px;
  max-width: 1050px;
  margin: 0 auto;
}

.item {
  flex: 0 1 calc(33.3% - 12px);
  display: flex;
  flex-direction: column;
  padding: 25px 25px 30px 20px;
  height: 255px;
  @media (max-width: 1700px) {
    flex: 0 1 calc(50% - 9px);
  }
  @media (max-width: 1400px) {
    flex: 1 1 100%;
    max-width: 337px;
    margin: 0 auto;
  }
  &-header {
    margin-bottom: 13px;
    span {
      color: $black;
      font-weight: 300;
      font-size: 13px;
      line-height: 24px;
    }
    i {
      font-size: 23px;
    }
  }
  &-name {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &-track {
    column-gap: 10px;
    .featured-avatar {
      position: relative;
      width: 40px;
      height: 40px;
      z-index: 1;
      img {
        width: inherit;
        height: inherit;
        border-radius: 50%;
        object-position: center;
        object-fit: cover;
        background-color: #ecedf1;
      }
      &::after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        width: inherit;
        height: inherit;
        border: 1px solid $black;
        border-radius: 50%;
        z-index: -1;
      }
    }
    p {
      margin-bottom: 0;
    }
  }
  &-link {
    margin-top: auto;
    column-gap: 14px;
    span {
      font-weight: 300;
      font-size: 15px;
      line-height: 15px;
      color: $black;
    }
    div {
      width: 27px;
      height: 27px;
    }
  }
}
