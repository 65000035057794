@import "../../../../assets/scss/variables.scss";

.profile-tabs {
  ul {
    display: flex;
    padding-bottom: 26px;
    margin-bottom: 50px;
    column-gap: 65px;
    border-bottom: 1px solid $white-edgard;
    li {
      position: relative;
      &.active::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -28px;
        width: 100%;
        height: 3px;
        background-color: $orange-yellow;
      }
      .chevron-mobile {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 12px;
        border-radius: 50%;
        background-color: $beige;
        i::before {
          color: $black;
        }
      }
    }
    .link-box {
      display: flex;
      column-gap: 13px;
      align-items: center;
      i {
        font-size: 20px;
      }
      a {
        font-weight: 300;
        font-size: 18px;
        line-height: 18px;
        color: $maastright-blue;
        &:hover {
          text-decoration: none;
        }
      }
    }
    @media (max-width: 1200px) {
      flex-direction: column;
      row-gap: 25px;
      margin-top: 30px;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: 0;
      li {
        position: relative;
        &.active::after {
          content: none;
        }
        .chevron-mobile {
          display: inline-flex;
        }
      }
    }
  }
}
