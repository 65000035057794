@import "variables";

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-bottom: 4px;

  &::before {
    content: "";
    position: absolute;
    width: 90%;
    height: 4px;
    background: $orange-yellow;
    display: block;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  label {
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 23px;
    color: $black;
    @media (max-width: 500px) {
      margin-bottom: 5px;
    }
  }
  input:not([role=combobox]) {
    height: 45px;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    padding-left: 10px;
    width: 100%;
  }

  textarea {
    width: 100%;
    background: $white;
    border: 1px solid $black;
    box-sizing: border-box;
    border-radius: 10px;
    position: relative;
    padding: 15px 20px;
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    resize: none;
    &::placeholder {
      color: $gray-light;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.inputError {
  input {
    border-color: $red;
  }
}

.errorMessage {
  color: $red;
  font-size: 14px;
  bottom: -22px;
  padding-left: 14px;
  display: block;
  text-align: left;
  position: absolute;
}

.textAreaContainer {
  + .errorMessage {
    position: unset;
  }
}