@import "../../../../assets/scss/variables.scss";

.edit-profile-form {
  .back-box {
    display: none;
    margin: 12px 0 30px;
    a {
      column-gap: 20px;
      .chevron-box {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border: 1px solid $black;
        border-radius: 50%;
      }
      span {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: $black;
      }

      i {
        transform: rotate(180deg);
        &::before {
          color: $black;
        }
      }
    }
    .save-btn {
      column-gap: 10px;
      padding: 8px 12px 7px;
      background-color: $effervescent-blue;
      color: $white;
      border-radius: 1000px;
      outline: 0;
      border: 0;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
      }
    }
    @media (max-width: 1200px) {
      display: flex;
    }
  }
  .formCol {
    .formFieldset {
      &:not(:first-child) {
        margin-top: 50px;
        @media (max-width: 1200px) {
          margin-top: 40px;
        }
      }
      & > div:not(:first-child) {
        margin-top: 25px;
        @media (max-width: 1200px) {
          margin-top: 0;
        }
      }
      & > div {
        row-gap: 20px;
      }
    }
    .fieldset-title {
      margin-bottom: 25px;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: $maastright-blue;
      @media (max-width: 1200px) {
        line-height: 27px;
        margin-bottom: 15px;
      }
    }
    @media (max-width: 1200px) {
      &:not(:first-child) {
        margin-top: 40px;
      }
      .mobile-mt {
        margin-top: 20px;
      }
      .mobile-mb {
        margin-bottom: 20px;
      }
    }
  }

  input::placeholder {
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    color: $gray-light;
  }
}
.save-box {
  margin-top: 40px;
  @media (min-width: 992px) {
    display: flex;
    justify-content: right;
  }
  .save-btn {
    position: relative;
    width: 100%;
    height: 55px;
    padding: 13px 17px;
    background-color: $effervescent-blue;
    color: $white;
    border-radius: 1000px;
    outline: 0;
    border: 0;
    @media (min-width: 992px) {
      width: auto;
      transition: background-color 0.2s ease-in-out;
      &:hover {
        background-color: #216fcb;
      }
    }
    span {
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
      @media (min-width: 992px) {
        margin-right: 100px;
      }
    }
    i {
      position: absolute;
      right: 21px;
      top: calc(50% - 8px);
      &::before {
        transform: scale(1.8);
      }
    }
  }
  // @media (max-width: 1200px) {
  //   display: block;
  // }
}
