@import "../../assets/scss/variables.scss";

.formRow {
  margin-bottom: 25px;
  @media (max-width: 991px) {
    margin-bottom: 20px;
    .full-mobile-radio {
      margin-bottom: 25px;
      margin-top: 15px !important;
    }
  }
  input::placeholder {
    font-weight: 300;
    font-size: 15px;
    line-height: 15px;
    color: $gray-light;
  }
}

.step-number {
  justify-content: center;
  margin-bottom: 30px;
  span {
    width: 55px;
    height: 55px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $black;
    border-radius: 50%;
    font-family: "Mulish", sans-serif;
    font-size: 18px;
    line-height: 30px;
  }
  @media (max-width: 991px) {
    display: flex;
  }
}

.mobile-form {
  @media (max-width: 991px) {
    .formRow > div:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.spaceBetweenHeaderFooter {
  margin-bottom: 200px;
  position: relative;
  @media (min-width: 1200px) {
    max-width: 1190px;
    padding: 0;
  }
  @media (max-width: 991px) {
    margin-top: 40px;
    margin-bottom: 50px;
  }

  &:after {
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1102' height='1000' viewBox='0 0 1102 1000' fill='none'%3E%3Cg clip-path='url(%23clip0_477_13687)'%3E%3Cpath d='M113.462 -132.951C116.475 -158.083 137.795 -177 163.107 -177L1146.24 -177C1175.64 -177 1198.7 -151.748 1196.03 -122.464L1098.69 946.165C1096.04 975.193 1069.23 995.815 1040.49 990.918L46.9078 821.583C20.6341 817.106 2.49125 792.806 5.6634 766.343L113.462 -132.951Z' fill='%23FFF8E8'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_477_13687'%3E%3Crect width='1102' height='1000' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    width: 1200px;
    height: calc(100% + 500px);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    display: block;
    z-index: -33;
    right: -41%;
    top: -181px;
    @media (min-width: 1921px) {
      width: 57vw;
      right: auto;
      left: 37%;
      height: calc(100vh + 110px);
    }
    @media (max-width: 1200px) {
      content: none;
    }
  }
}

.stepTitle {
  font-size: 42px;
  line-height: 52px;
  margin-bottom: 25px;
  color: $maastright-blue;
  & ~ p {
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    max-width: 450px;
    color: $black;
    @media (max-width: 1200px) {
      max-width: none;
    }
  }
  @media (max-width: 991px) {
    font-size: 26px;
    line-height: 39px;
    text-align: center;
    // br{
    //     display: none;
    // }
  }
}

.step {
  margin-top: 100px;
  .prev-step {
    span {
      cursor: pointer;
    }
  }
  .formRow:last-child {
    margin-top: 40px;
    margin-bottom: 0;
  }
  @media (max-width: 991px) {
    .step-text {
      padding: 5px;
      text-align: center;
      h2 {
        margin-bottom: 15px;
      }
      p {
        font-size: 15px;
        line-height: 25px;
        font-weight: 300;
        margin-bottom: 30px;
      }
    }
    .formRow:last-child {
      flex-direction: column-reverse;
      .prev-step {
        justify-content: center;
        font-weight: 500;
      }
      & > div {
        margin-top: 35px;
        text-align: center;
      }
    }
  }
}

.errorMessage {
  color: $red;
  a {
    color: $maastright-blue;
    text-decoration: underline;
  }
}
// .stepDescription {
//     font-family: Sofia Pro;
//     font-style: normal;
//     font-weight: 300;
//     font-size: 18px;
//     font-size: 42px;
//     line-height: 30px;
//     /* or 167% */
//     color: #000000;
// }
