 @import "../../assets/scss/variables.scss";

.button {
  width: 100%;
  height: 64px;
  min-width: 208px;
  background: $effervescent-blue;
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  padding-right: 17px;

  .button-text {
    flex-grow: 1;
    margin-bottom: 0;
    text-align: left;
    color: $white;
    line-height: 25px;
  }

  span {
    position: absolute;
    top: calc(50% - 15px);
    right: 17px;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.15s ease-in-out;
    i {
      font-size: 14px;
    }
  }

  &:active {
    outline: none;
    background: lighten($effervescent-blue, 10%);
  }

  &:focus {
    outline: none;
  }

  &:hover {
    span {
      background-color: $white;
      i::before {
        color: $effervescent-blue;
      }
    }
    &::before {
      transform: translate(-4px, -6px);
    }
  }

  &::before {
    content: "";
    position: absolute;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: inherit;
    width: inherit;
    height: inherit;
    z-index: -1;
    top: 6px;
    right: -4px;
    transform: translate(0px, 0px);
    transition: transform 0.2s ease-in-out;
  }
  &.button-white {
    background: $white;
    .button-text {
      color: $black;
    }
    span {
      border-color: $black;
      i:before {
        color: $black;
      }
    }
    &::before {
      background-color: $orange-yellow;
      border-color: $orange-yellow;
    }
  }
}
