@import "../../../../../../assets/scss/variables.scss";

.title-box {
  max-width: 1050px;
  margin: 0 auto 40px;
  scroll-margin-top: 180px;
  gap: 20px;
  h2 {
    margin-bottom: 0;
  }
  .sort {
    cursor: pointer;
    span {
      font-weight: 300;
      font-size: 13px;
      line-height: 13px;
      color: $black;
    }
    .down {
      margin-top: 3px;
    }
    .up {
      transform: rotate(180deg);
      align-self: flex-end;
      margin-bottom: 3px;
    }
    i {
      display: block;
      font-size: 8px;
    }
  }
}

.topics-items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
  padding-bottom: 40px;
  max-width: 1050px;
  margin: 0 auto;
}
.topic-list-wrapp {
  width: 100%;
  h6 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 5px;
    color: $maastright-blue;
  }
  .description {
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    color: $black;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 20px;
  }
  button {
    background-color: transparent;
    border: 0;
    outline: 0;
    font-size: 25px;
    color: $black;
    padding: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
.no-result {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: $maastright-blue;
  text-align: center;
  margin-top: 50px;
}
.topic-wrapp {
  flex: 0 1 calc(33.3% - 14px);
  @media (max-width: 1700px) {
    flex: 0 1 calc(50% - 10px);
  }
  @media (max-width: 1400px) {
    flex: 1 1 100%;
    max-width: 336px;
    margin: 0 auto;
  }
}
