@import "../../../../../../assets/scss/variables.scss";

.featured-topic-item {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
  .topic-wrapp {
    flex: 0 0 31%;
    height: 412px;
    padding-bottom: 5px;
  }

  .podcasts-box {
    padding: 15px 40px 15px 80px;
    margin-left: -20px;
    flex-grow: 1;
    border-radius: 0 25px 25px 0;
    background-color: $beige;
    .podcasts-wrapp {
      border-bottom: 1px solid $white-edgard;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
