@import "../../../../../../assets/scss/variables.scss";

.container {
  scroll-margin-top: 180px;
  margin-top: 80px;
}
.speaker {
  &-header {
    margin-bottom: 40px;
  }
  .speaker-header-info {
    column-gap: 25px;
    div {
      position: relative;
      flex-shrink: 0;
      width: 110px;
      height: 110px;
      z-index: 1;

      & > div {
        border-radius: 50%;
        overflow: hidden;
        img {
          object-position: center;
          object-fit: cover;
          width: 100%;
          height: 100%;
          background-color: #ecedf1;
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        width: inherit;
        height: inherit;
        border: 1px solid $black;
        border-radius: 50%;
        z-index: -1;
      }
      i {
        position: absolute;
        right: -15px;
        bottom: -15px;
        z-index: 1;
      }
    }
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      color: $maastright-blue;
      margin-bottom: 0;
    }
  }
  .speaker-header-actions {
    column-gap: 20px;
    a {
      width: 160px;
      height: 40px;
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;
      color: $effervescent-blue;
      &.speaker-save {
        column-gap: 10px;
        border: 1px solid $effervescent-blue;
        border-radius: 1000px;
        cursor: pointer;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
        i {
          font-size: 17px;
        }

        &.saved,
        &:hover {
          background-color: $effervescent-blue;
          color: $white;
        }
      }
    }
  }
  &-descr {
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    color: $black;
    margin-bottom: 30px;
  }
}
