.image-wrap {
  margin: 0 auto;
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
  position: relative;
  img {
    position: absolute;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    box-sizing: border-box;
    &.img-bg {
      left: -22px;
      top: -14px;
      max-width: none;
      width: 115px;
      height: 100px;
      border-radius: 0;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 3px;
    left: 3.5px;
    border: 1px solid #021c3c;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;
  }
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  font-family: Poppins;
  color: #021c3c;
}
.description {
  text-align: center;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 25px;
}
.review-text {
  max-width: 764px;
  padding: 0 15px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 35px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 24px;
    max-width: 310px;
  }
}
