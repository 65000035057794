@import "../../../../../assets/scss/variables.scss";

.featured-items {
  display: flex;
  flex-wrap: wrap;
  column-gap: 18px;
  row-gap: 40px;
  padding-bottom: 40px;
  max-width: 1050px;
  margin: 0 auto;
}

.podcast-actions {
  max-width: 1050px;
  margin: 0 auto 45px;
  & > div {
    column-gap: 25px;
    button {
      font-size: 22px;
    }
  }
  &__share {
    display: flex;
    -webkit-transition: all 0.5s ease-in;
    transition: all 0.5s ease-in;
    width: 159px;
    border-radius: 8px;
    box-shadow: 0px 0px 0px 1px #021c3c;
  }
  &__share--closed {
    width: 0;
    overflow: hidden;
    box-shadow: 0px 0px 0px 0px #ffffff;
  }
  &__share--open {
    -webkit-animation: scale-in-hor-left 0.5s ease-in both;
    animation: scale-in-hor-left 0.5s ease-in both;
  }
  > a {
    padding: 11px 20px 12px;
    font-weight: 500;
    font-size: 15px;
    line-height: 12px;
    color: $effervescent-blue;
    column-gap: 10px;
    border: 1px solid $effervescent-blue;
    border-radius: 1000px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
    i {
      font-size: 17px;
    }
    &:hover {
      background-color: $effervescent-blue;
      color: $white;
    }
  }
}

.podcast-body {
  column-gap: 50px;
  max-width: 1050px;
  margin: 0 auto 75px;
  & > div {
    flex: 0 0 calc(50% - 25px);
  }
  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      font-weight: 300;
      font-size: 15px;
      line-height: 24px;
      color: $black;
    }
    .difficulty {
      margin-bottom: 20px;
      span {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 5px;
        background-color: $beige;
      }
    }
    .category {
      margin-bottom: 10px;
       a {
         color: $black;
       }
    }
    .podcast-name {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 30px;
      line-height: 45px;
      color: $maastright-blue;
      margin-bottom: 25px;
    }
    .author {
      column-gap: 10px;

      a {
        color: $black;
      }
      &-avatar {
        position: relative;
        width: 43px;
        height: 43px;
        flex-shrink: 0;
        z-index: 1;
        img {
          width: inherit;
          height: inherit;
          border-radius: 50%;
          object-position: center;
          object-fit: cover;
          background-color: #ecedf1;
        }
        &::after {
          content: "";
          position: absolute;
          top: 2px;
          left: 2px;
          width: inherit;
          height: inherit;
          border: 1px solid $black;
          border-radius: 50%;
          z-index: -1;
        }
      }
      p {
        margin-bottom: 0;
      }
    }
  }
  .player {
  }
}

.recomended-podcast {
  max-width: 1050px;
  margin: 0 auto;
  & > p {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 42px;
    color: $maastright-blue;
    margin-bottom: 25px;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-6-1 14:41:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
