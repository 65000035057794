@import "../../assets/scss/variables.scss";

.contact-us-container {
  @media (min-width: 1200px) {
    max-width: 1170px;
    padding: 0;
  }
}

.contact-us {
  padding: 130px 0;
  position: relative;
  &:after {
    position: absolute;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1102' height='1000' viewBox='0 0 1102 1000' fill='none'%3E%3Cg clip-path='url(%23clip0_477_13687)'%3E%3Cpath d='M113.462 -132.951C116.475 -158.083 137.795 -177 163.107 -177L1146.24 -177C1175.64 -177 1198.7 -151.748 1196.03 -122.464L1098.69 946.165C1096.04 975.193 1069.23 995.815 1040.49 990.918L46.9078 821.583C20.6341 817.106 2.49125 792.806 5.6634 766.343L113.462 -132.951Z' fill='%23FFF8E8'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_477_13687'%3E%3Crect width='1102' height='1000' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    min-width: 1200px;
    width: 100%;
    height: calc(100vh + 110px);
    background-repeat: no-repeat;
    background-position: bottom left;
    background-size: cover;
    display: block;
    z-index: -33;
    right: -41%;
    top: -81px;
    @media (min-width: 1921px) {
      width: 57vw;
      right: auto;
      left: 35%;
      top: -181px;
    }
    @media (max-width: 1200px) {
      content: none;
    }
  }
  &-mobile {
    display: none;
  }
  @media (max-width: 991px) {
    padding: 40px 0 50px;
    &-mobile {
      display: block;
      h2 {
        font-size: 26px;
        line-height: 39px;
        @media (max-width: 991px) {
          margin-bottom: 15px;
        }
      }
      p {
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        @media (max-width: 991px) {
          margin-bottom: 35px;
        }
      }
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
    .row-mobile {
      flex-direction: column-reverse;
    }
  }
  .stepTitle {
    font-size: 52px;
    line-height: 62px;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .contact-us__info {
    margin-top: 50px;
    @media (max-width: 991px) {
      text-align: center;
    }
    &-ico {
      width: 55px;
      height: 55px;
      border: 1px solid $black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      i {
        font-size: 23px;
      }
      @media (max-width: 991px) {
        margin: 0 auto 15px;
      }
    }
    p {
      margin-bottom: 0;
      font-weight: 300;
      font-size: 18px;
      line-height: 28px;
    }
  }
  .form {
    padding-bottom: 15px;
    .formRow {
      & > div {
        margin-bottom: 20px;
      }
    }
    .button-row {
      & > div {
        margin-top: 15px;
      }
      button {
        max-width: 240px;
        @media (max-width: 991px) {
          max-width: none;
          p {
            text-align: center;
            padding-right: 10px;
          }
        }
      }
    }
    @media (max-width: 991px) {
      padding-bottom: 0;
    }
  }
}
