@import "../../../../../../assets/scss/variables.scss";

.social-box {
  text-align: center;
  display: flex;
  align-items: center;
  max-width: 1050px;
  margin: 40px auto;

  p {
    font-size: 13px;
    line-height: 20px;
    color: $maastright-blue;
    margin: 0 10px 0 0;
  }
  ul {
    justify-content: center;
    column-gap: 10px;
    li {
      display: inline-flex;
      a {
        display: inline-flex;
      }
    }
  }
}