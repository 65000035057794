@import "../../../../../../assets/scss/variables.scss";

.profile-payments-table {
  margin-bottom: 20px;
  td,
  th {
    padding: 20px 25px;
    font-size: 15px;
    line-height: 24px;
  }
  thead tr {
    border: 1px solid $desired-down;
  }
  thead th {
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    // border-width: 1px;
    border: none;
    color: $maastright-blue;
    i {
      font-size: 7px;
    }
    &.filter {
      cursor: pointer;
    }
  }
  tbody th {
    font-weight: 600;
  }
  tbody td {
    font-weight: 300;
  }
  @media (max-width: 1200px) {
    thead {
      display: none;
    }
    margin: 0 -15px;
    tr {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      row-gap: 10px;
      border-bottom: 1px solid $desired-down;
      th,
      td {
        padding: 0 20px;
        border-top: 0;
      }
      th {
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
      }
      td {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
      th + td {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        padding-bottom: 5px;
      }
    }
  }
}
.load-box {
  display: none;
  margin-top: 35px;
  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
  }
}


.filter-btn {
  display: flex;
  background-color: transparent;
  border: 0;
  outline: 0;
  font-size: 13px;
  line-height: 13px;
  color: $maastright-blue;
  padding: 0;
  &:focus {
    outline: 0;
  }
  .down {
    margin-top: -1px;
  }
  .up {
    transform: rotate(180deg);
    margin-top: 2px;
  }
  i {
    font-size: 7px;
  }
}