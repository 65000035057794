$white-edgard: #ededed;
$maastright-blue: #021c3c;
$orange-yellow: #fcb912;
$effervescent-blue: #042b59;
$black: #000000;
$rich-black: #030406;
$white: white;
$beige: #fff8e8;
$gray: #e3e3e3;
$gray-light: #7e7e7e;
$lucidity: #183c66;
$red: #f51d00;
$bisquit: #feefc9;
$bank-vault: #747474;
$troller-gray: #828282;
$desired-down: #d8d8d8;
$cta-foreground: #0F2745;
$greyGradient: linear-gradient(#021C3C00, #021C3C);
